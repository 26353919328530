























































































import { useContext, useRouter, computed, onMounted, watch, ref, PropType, onBeforeMount } from '@nuxtjs/composition-api';
import { useProductsWithCommonProductCardProps } from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';
import ProductCardVariantSearch from '../../Products/Search/ProductCardVariantSearch.vue';
import { SfScrollable, SfLink, SfLoader, SfSkeleton } from '@storefront-ui/vue';
import { ProductRepository } from '../../../repository/products.repository';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { Product } from '~/modules/GraphQL/types';
import { useIsMobile } from '@/helpers/mobileDevices';
const __sfc_main = {};
__sfc_main.props = {
  visible: {
    type: Boolean,
    default: false
  },
  loadingProduct: {
    type: Boolean,
    default: false
  },
  searchTerm: {
    type: String,
    default: ''
  },
  debouncedSearchTerm: {
    type: String,
    default: ''
  },
  searchResults: {
    type: (Array as PropType<Product[] | null>),
    default: () => []
  },
  bestProducts: {
    type: (Array as PropType<Product[] | null>),
    default: () => []
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const {
    isMobile
  } = useIsMobile();
  const props = __props;
  const emit = __ctx.emit;
  const {
    app
  } = useContext();
  const router = useRouter();
  const {
    isInWishlist,
    load: loadWishlist
  } = useWishlist();
  const wishlistStore = useWishlistStore();
  const totalSkeleton = computed(() => isMobile.value ? 2 : 7);
  const loading = ref(true);
  const finalSearchProducts = ref([]);
  const wishlistProductsList = ref([]);
  const searchResultsWithWishlistInfo = ref([]);
  const productRepository = new ProductRepository();
  const categories = ref(null);
  const products = computed(() => props.bestProducts || []);
  const {
    productsWithCommonProductCardProps
  } = useProductsWithCommonProductCardProps(products);
  const getBestCategorySearch = async () => {
    try {
      return await productRepository.getBestCategory();
    } catch (e) {}
  };
  onMounted(async () => {
    if (!wishlistStore.wishlist.id) {
      await loadWishlist();
    }
    categories.value = await getBestCategorySearch();
  });
  watch(() => props.bestProducts, newBestProducts => {
    if (newBestProducts && newBestProducts.length > 0) {
      products.value = newBestProducts;
      loading.value = false;
    }
  }, {
    immediate: true
  });
  watch(() => props.searchResults, newSearchResults => {
    if (newSearchResults && newSearchResults.length > 0) {
      searchResultsWithWishlistInfo.value = newSearchResults.map(product => ({
        ...product,
        isInWishlist: isInWishlist({
          product
        })
      }));
    } else {
      searchResultsWithWishlistInfo.value = [];
    }
  }, {
    immediate: true
  });
  watch([searchResultsWithWishlistInfo, productsWithCommonProductCardProps], ([searchProducts, bestProducts]) => {
    if (searchProducts.length > 0) {
      finalSearchProducts.value = searchProducts;
    } else {
      wishlistProductsList.value = bestProducts;
    }
  }, {
    immediate: true
  });
  watch(() => props, () => {
    props.loadingProduct = props.searchTerm !== props.debouncedSearchTerm;
  });
  const redirectTLink = async (link: string) => {
    await router.push(app.localePath('/' + link));
    await closeSearchModal();
  };
  const redirectCategories = async (link: string) => {
    await router.push(app.localePath('/c/' + link + '.html'));
    await closeSearchModal();
  };
  const closeSearchModal = () => {
    emit('update:closeSearchModal');
  };
  return {
    totalSkeleton,
    loading,
    finalSearchProducts,
    wishlistProductsList,
    categories,
    redirectTLink,
    redirectCategories,
    closeSearchModal
  };
};
__sfc_main.components = Object.assign({
  SfLoader,
  SfScrollable,
  SfSkeleton,
  ProductCardVariantSearch
}, __sfc_main.components);
export default __sfc_main;
