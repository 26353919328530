











































import iconMobileJson from '@/components/Header/Shortcuts/Icons-mobile.json';
import HeaderLogo from './HeaderLogo.vue';
import HeaderShortcuts from '@/components/Header/Shortcuts/Shortcut.vue';
import { SfButton } from '@storefront-ui/vue';
import { useCategoryStore } from '@/modules/catalog/category/stores/category';
import { useUiState } from '@/composables';
import MobileCategorySidebar from '@/modules/catalog/category/components/sidebar/MobileCategorySidebar/MobileCategorySidebar.vue';
import { ref, onBeforeUnmount, onMounted, nextTick } from '@nuxtjs/composition-api';
import Search from './SearchBar/Search.vue';
const __sfc_main = {};
__sfc_main.props = {
  isLogin: {
    type: Boolean,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const isSearchOpen = ref<boolean>(false);
  const searchRefMobile = ref(null);
  const {
    toggleMobileMenu,
    isMobileMenuOpen
  } = useUiState();
  const handleMenuClick = () => {
    loadMenu();
  };
  const openSearch = () => {
    isSearchOpen.value = true;
  };
  const closeSearch = () => {
    isSearchOpen.value = false;
  };
  const handleClickOutside = (event: MouseEvent) => {
    const target = (event.target as HTMLElement);
    if (target.closest('.sf-header__action.mobile-search')) {
      return;
    }
    if (!searchRefMobile.value?.contains(target) && isSearchOpen.value === true) {
      closeSearch();
    }
  };
  const loadMenu = async () => {
    const categories = useCategoryStore();
    if (categories.categories === null) {
      await categories.load();
    }
    toggleMobileMenu();
  };
  onMounted(async () => {
    await nextTick();
    document.addEventListener('click', event => handleClickOutside(event));
  });
  onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
  });
  return {
    iconMobileJson,
    isSearchOpen,
    searchRefMobile,
    isMobileMenuOpen,
    handleMenuClick,
    openSearch,
    closeSearch
  };
};
__sfc_main.components = Object.assign({
  SfButton,
  HeaderLogo,
  HeaderShortcuts,
  MobileCategorySidebar,
  Search
}, __sfc_main.components);
export default __sfc_main;
