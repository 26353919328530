import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_plugin_7c5c0a26 from 'nuxt_plugin_plugin_7c5c0a26' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_nuxtplugin83195b12_43703de3 from 'nuxt_plugin_nuxtplugin83195b12_43703de3' // Source: ./nuxt.plugin.83195b12.js (mode: 'server')
import nuxt_plugin_vuescrollto_5abb1fb5 from 'nuxt_plugin_vuescrollto_5abb1fb5' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_1405a77c from 'nuxt_plugin_cookieuniversalnuxt_1405a77c' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginutils_763b60e7 from 'nuxt_plugin_pluginutils_763b60e7' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginseo_795c61fb from 'nuxt_plugin_pluginseo_795c61fb' // Source: ./nuxt-i18n/plugin.seo.js (mode: 'all')
import nuxt_plugin_pluginrouting_ea315f1c from 'nuxt_plugin_pluginrouting_ea315f1c' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_6dbec03d from 'nuxt_plugin_pluginmain_6dbec03d' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_distplugin3b8d2c68_02bcf09c from 'nuxt_plugin_distplugin3b8d2c68_02bcf09c' // Source: ./dist.plugin.3b8d2c68.mjs (mode: 'all')
import nuxt_plugin_image_ecb5a662 from 'nuxt_plugin_image_ecb5a662' // Source: ./image.js (mode: 'all')
import nuxt_plugin_magentoplugine59773b6_7529fd9e from 'nuxt_plugin_magentoplugine59773b6_7529fd9e' // Source: ./magento.plugin.e59773b6.ts (mode: 'all')
import nuxt_plugin_deviceplugin_e37a9942 from 'nuxt_plugin_deviceplugin_e37a9942' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_workbox_1cbc0020 from 'nuxt_plugin_workbox_1cbc0020' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_a69751fa from 'nuxt_plugin_metaplugin_a69751fa' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_9d480712 from 'nuxt_plugin_iconplugin_9d480712' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_tokenexpired_ca1e20ca from 'nuxt_plugin_tokenexpired_ca1e20ca' // Source: ../plugins/token-expired (mode: 'all')
import nuxt_plugin_i18n_6a80ea94 from 'nuxt_plugin_i18n_6a80ea94' // Source: ../plugins/i18n (mode: 'all')
import nuxt_plugin_fcPlugin_5a846bc6 from 'nuxt_plugin_fcPlugin_5a846bc6' // Source: ../plugins/fcPlugin (mode: 'all')
import nuxt_plugin_dompurify_9e093c4a from 'nuxt_plugin_dompurify_9e093c4a' // Source: ../plugins/dompurify (mode: 'all')
import nuxt_plugin_graphqlClient_796ef55e from 'nuxt_plugin_graphqlClient_796ef55e' // Source: ../plugins/graphqlClient (mode: 'all')
import nuxt_plugin_storeConfigPlugin_234467e0 from 'nuxt_plugin_storeConfigPlugin_234467e0' // Source: ../plugins/storeConfigPlugin (mode: 'all')
import nuxt_plugin_leaflet_46750006 from 'nuxt_plugin_leaflet_46750006' // Source: ../plugins/leaflet.ts (mode: 'client')
import nuxt_plugin_vue3html2pdf_67553ad2 from 'nuxt_plugin_vue3html2pdf_67553ad2' // Source: ../plugins/vue3-html2pdf (mode: 'client')
import nuxt_plugin_GoogleAnalytics_347e74d6 from 'nuxt_plugin_GoogleAnalytics_347e74d6' // Source: ../plugins/GoogleAnalytics.ts (mode: 'client')
import nuxt_plugin_distpluginnuxt277ff9eb9_7b5a8030 from 'nuxt_plugin_distpluginnuxt277ff9eb9_7b5a8030' // Source: ./dist.plugin-nuxt2.77ff9eb9.js (mode: 'all')
import nuxt_plugin_meta_51ec36b8 from 'nuxt_plugin_meta_51ec36b8' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":true,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Promulias Paris - Version Professionnelle","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"Site web B2B de la sociétée Promulias Paris","content":"Site web B2B de la sociétée Promulias Paris"},{"name":"generator","content":"Vue Storefront 2"},{"hid":"charset","charset":"utf-8"},{"hid":"mobile-web-app-capable","name":"mobile-web-app-capable","content":"yes"},{"hid":"apple-mobile-web-app-title","name":"apple-mobile-web-app-title","content":"Promulias Paris - Version Professionnelle"},{"hid":"author","name":"author","content":"GYB"},{"hid":"theme-color","name":"theme-color","content":"#000000"},{"hid":"og:type","name":"og:type","property":"og:type","content":"website"},{"hid":"og:title","name":"og:title","property":"og:title","content":"Promulias Paris - Version Professionnelle"},{"hid":"og:site_name","name":"og:site_name","property":"og:site_name","content":"Promulias Paris - Version Professionnelle"},{"hid":"og:description","name":"og:description","property":"og:description","content":"Site web B2B de la sociétée Promulias Paris"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"hid":"shortcut-icon","rel":"shortcut icon","href":"\u002F_nuxt\u002Ficons\u002Ficon_64x64.7d893a.png"},{"hid":"apple-touch-icon","rel":"apple-touch-icon","href":"\u002F_nuxt\u002Ficons\u002Ficon_512x512.7d893a.png","sizes":"512x512"},{"rel":"manifest","href":"\u002F_nuxt\u002Fmanifest.83e3e128.json","hid":"manifest"}],"style":[],"script":[],"htmlAttrs":{"lang":"fr"}},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_7c5c0a26 === 'function') {
    await nuxt_plugin_plugin_7c5c0a26(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_nuxtplugin83195b12_43703de3 === 'function') {
    await nuxt_plugin_nuxtplugin83195b12_43703de3(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_5abb1fb5 === 'function') {
    await nuxt_plugin_vuescrollto_5abb1fb5(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_1405a77c === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_1405a77c(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_763b60e7 === 'function') {
    await nuxt_plugin_pluginutils_763b60e7(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginseo_795c61fb === 'function') {
    await nuxt_plugin_pluginseo_795c61fb(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_ea315f1c === 'function') {
    await nuxt_plugin_pluginrouting_ea315f1c(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_6dbec03d === 'function') {
    await nuxt_plugin_pluginmain_6dbec03d(app.context, inject)
  }

  if (typeof nuxt_plugin_distplugin3b8d2c68_02bcf09c === 'function') {
    await nuxt_plugin_distplugin3b8d2c68_02bcf09c(app.context, inject)
  }

  if (typeof nuxt_plugin_image_ecb5a662 === 'function') {
    await nuxt_plugin_image_ecb5a662(app.context, inject)
  }

  if (typeof nuxt_plugin_magentoplugine59773b6_7529fd9e === 'function') {
    await nuxt_plugin_magentoplugine59773b6_7529fd9e(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_e37a9942 === 'function') {
    await nuxt_plugin_deviceplugin_e37a9942(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_1cbc0020 === 'function') {
    await nuxt_plugin_workbox_1cbc0020(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_a69751fa === 'function') {
    await nuxt_plugin_metaplugin_a69751fa(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_9d480712 === 'function') {
    await nuxt_plugin_iconplugin_9d480712(app.context, inject)
  }

  if (typeof nuxt_plugin_tokenexpired_ca1e20ca === 'function') {
    await nuxt_plugin_tokenexpired_ca1e20ca(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_6a80ea94 === 'function') {
    await nuxt_plugin_i18n_6a80ea94(app.context, inject)
  }

  if (typeof nuxt_plugin_fcPlugin_5a846bc6 === 'function') {
    await nuxt_plugin_fcPlugin_5a846bc6(app.context, inject)
  }

  if (typeof nuxt_plugin_dompurify_9e093c4a === 'function') {
    await nuxt_plugin_dompurify_9e093c4a(app.context, inject)
  }

  if (typeof nuxt_plugin_graphqlClient_796ef55e === 'function') {
    await nuxt_plugin_graphqlClient_796ef55e(app.context, inject)
  }

  if (typeof nuxt_plugin_storeConfigPlugin_234467e0 === 'function') {
    await nuxt_plugin_storeConfigPlugin_234467e0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_leaflet_46750006 === 'function') {
    await nuxt_plugin_leaflet_46750006(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vue3html2pdf_67553ad2 === 'function') {
    await nuxt_plugin_vue3html2pdf_67553ad2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_GoogleAnalytics_347e74d6 === 'function') {
    await nuxt_plugin_GoogleAnalytics_347e74d6(app.context, inject)
  }

  if (typeof nuxt_plugin_distpluginnuxt277ff9eb9_7b5a8030 === 'function') {
    await nuxt_plugin_distpluginnuxt277ff9eb9_7b5a8030(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_51ec36b8 === 'function') {
    await nuxt_plugin_meta_51ec36b8(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
